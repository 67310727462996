import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import Layout from "../../components/layout"
import SEO from "/src/components/seo"
import BannerFullScreen from "../../components/BannerFullScreen/BannerFullScreen"
import ButtonContact from "../../components/BannerFullScreen/ButtonContact"
import OrderedCard from "../../components/OrderedCard/OrderedCard"
import WhiteBox from "../../components/ColoredBoxes/WhiteBox"
import QuoteBlocks from "../../components/QuoteBlock/QuoteBlock"
import "./Services.scss"
import { Link } from "gatsby"
import CustomAccordion from "../../components/Accordion"

const bannerContent = {
    title: "AI Consulting Services",
    content: "Unlock the potential of artificial intelligence with Neurond’s customized AI consulting and development services, paving your way to industry leadership.",
    backgroundImage: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting/ai-consulting-banner.jpg`
}

const businessGrowth = [
    {
        title: "Expert Guidance",
        content: "AI consultants can understand and assess your specific needs and recommend the most suitable AI solutions to address them, saving you valuable time and resources."
    },
    {
        title: "Seamless Implementation",
        content: "We possess the expertise to navigate the implementation process, ensuring a smooth transition that minimizes disruption and maximizes results."
    },
    {
        title: "Data-Driven Strategies",
        content: "Our experts can help you analyze your data landscape, identify valuable insights, and develop data-driven AI strategies that deliver tangible outcomes."
    },
    {
        title: "Maximized ROI",
        content: "An AI consulting company will ensure your AI implementation is tailored to your specific business goals and objectives to maximize the ROI in AI technology."
    },
    {
        title: "Future-Proofing Your Business",
        content: "Neurond can assist you in staying ahead of the curve by identifying emerging AI trends. This proactive approach keeps your business competitive and prepared for the future."
    }
]

const solutions = [
    {
        title: "Intelligent Automation Consulting",
        content: "Experience productivity improvement with <span class=\"d-inline-block\">end-to-end</span> automation consulting.",
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting/icon-light.png`
    },
    {
        title: "Intelligent Analytics Consulting",
        content: "Get actionable insights from your data by leveraging the latest technologies.",
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting/icon-analytics.png`
    },
    {
        title: "Digital Transformation Service",
        content: "Maintain cutting-edge relevance in today's <span class=\"d-inline-block\">rapidly evolving</span> digital landscape.",
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting/icon-file.png`
    }
]

const approachs = [
    {
        title: "Conduct Assessment",
        content: "Evaluate your platform to have a holistic understanding and build a strategic project plan."
    },
    {
        title: "Prepare Data",
        content: "Ensure your data is ready for modeling and training. Produce well founded datasets if needed."
    },
    {
        title: "Evaluate and Refine Model",
        content: "Put a series of deep learning algorithms to test. Constantly refine them for the highest efficiency level."
    },
    {
        title: "Deploy Model",
        content: "Move the solution into production once it assures quality and answers business requirements."
    }
]

const customSolutions = [
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting/icon-finance.png`,
        title: "Finance",
        content: "Make finance and banking services more efficient, personalized, and secure.",
        services: ["Predict market trends", "Automate trading activities", "Evaluate creditworthiness", "Detect frauds"]
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting/icon-edu.png`,
        title: "Education",
        content: "Adapt to students' learning styles to increase engagement and learning outcomes.",
        services: ["Personalize learning", "Automate grading", "Assist language learning", "Guide career choices"]
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting/icon-health.png`,
        title: "Healthcare and Wellness",
        content: "Enhance healthcare delivery and patient outcomes with our AI-based solutions.",
        services: ["Detect diseases earlier", "Personalize treatments", "Enable robotic surgery", "Analyze genetic data"]
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting/icon-ecommerce.png`,
        title: "E-commerce and Retail",
        content: "Improve personalization, customer relationship management, and logistics",
        services: ["Track E-commerce trends", "Analyze customer behavior", "Give taitored recommendations", "Allow visual product search"]
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting/icon-auto.png`,
        title: "Automotive",
        content: "Enhance efficiency, safety, and sustainability in transportation",
        services: ["Enable self-driving", "Provide driver assistance", "Manage traffic flow", "Predict maintenance needs"]
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting/icon-mkt.png`,
        title: "Marketing and Advertising",
        content: "Fuel sales and fast-track your marketing transformation",
        services: ["Forecast campaign performance", "Analyze sentiment", "Generate content types", "Optimize email campaigns"]
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting/icon-logistics.png`,
        title: "Logistics and Supply Chain",
        content: "Reduce costs and make supply chain processes more responsive to changes",
        services: ["Anticipate future demand", "Perform labor-intensive tasks", "Predict required maintenance", "Identify potential risks"]
    }
]

const clientList = [
    {
        url: `${process.env.GATSBY_CDN_CONFIG}/v2/client-logos/logo-incloud.png`,
        linkTo: "/company/case-studies/incloud"
    },
    {
        url: `${process.env.GATSBY_CDN_CONFIG}/v2/client-logos/logo-conexus.png`,
        linkTo: "/company/case-studies/conexus"
    },
    {
        url: `${process.env.GATSBY_CDN_CONFIG}/v2/client-logos/logo-orient.png`,
        linkTo: "/company/case-studies/orient"
    },
    {
        url: `${process.env.GATSBY_CDN_CONFIG}/v2/client-logos/logo-netwealth.png`,
        linkTo: "/company/case-studies/netwealth"
    },
    {
        url: `${process.env.GATSBY_CDN_CONFIG}/v2/client-logos/logo-dekra.png`,
        linkTo: "/company/case-studies/dekra"
    },
    {
        url: `${process.env.GATSBY_CDN_CONFIG}/v2/client-logos/logo-bravo.png`,
        linkTo: "/company/case-studies/bravo"
    },
    {
        url: `${process.env.GATSBY_CDN_CONFIG}/v2/client-logos/logo-fishwell.png`,
        linkTo: "/company/case-studies/fishwell"
    },

]

const quoteBlocks = [
    {
        logo: `${process.env.GATSBY_CDN_CONFIG}/v2/client-logos/logo-bravo.png`,
        content: "Thanks to Neurond’s Dr.Parser, we’re able to create a compelling talent management system that every HR loves.",
        quoter: "John P.",
        quoterTitle: "Product Development Manager"
    },
    {
        logo: `${process.env.GATSBY_CDN_CONFIG}/v2/client-logos/logo-fishwell.png`,
        content: "Neurond’s AI team has constantly impressed us. Our team at Neurond, within a few months, became a vital and integrated part of Fishwell, sharing the same vision and drive forward, and representing a key force of Fishwell.",
        quoter: "Thomas H.",
        quoterTitle: "CTO"
    },
    {
        logo: `${process.env.GATSBY_CDN_CONFIG}/v2/client-logos/logo-incloud.png`,
        content: "Neurond’s most impressive characteristic is their passion for helping us solve our problems. They truly believe in what we’re trying to achieve. Their members see the value of our work and understand our vision.",
        quoter: "Christopher B.",
        quoterTitle: "Managing Director"
    }
]

const faqs =
[
    {
      title: "What are AI Consulting Services?",
      description: "AI consulting services provide professional guidance to businesses looking to integrate artificial intelligence into their operations. The consultants will assess businesses’ needs, identify potential AI solutions, and guide through the implementation process.\n\nBy tapping into the expertise of specialized consultants, companies without in-house AI knowledge can navigate the complexities of AI adoption, ensuring their investments align with business objectives and are implemented effectively to gain a competitive edge.",
    },
    {
      title: "How can AI Consulting benefit my business?",
      description: "Your business will experience various benefits when using our AI consulting service. We can significantly enhance your business by developing a bespoke AI strategy that aligns with your goals, improving operational efficiency through automation, facilitating better decision-making with data-driven insights, and spurring innovation by introducing advanced AI technologies.\n\nOur skilled consultants assist in mitigating risks of AI adoption, guaranteeing scalability, providing specialized expertise, and optimizing your existing resources, ensuring that your investment drives value and maintains a competitive edge in the market.",
    },
    {
      title: "What types of AI projects do you consult on?",
      description: "Our AI consultants have expertise in a variety of areas, including automation, machine learning, natural language processing, and computer vision.",
    },
    {
      title: "Do I need a technical background to utilize AI Consulting Services?",
      description: "No, you don’t necessarily need a technical background to utilize AI consulting services. Our AI consultants can explain complex concepts in a clear and concise way. They communicate the benefits and implications of AI in understandable terms and help to identify opportunities where AI can add value. With their assistance, you can make informed decisions about investing in AI without having to be an expert in the field yourself. The consultants will manage the technical aspects, including development, deployment, and maintenance of AI systems, while working closely with your team to ensure that the solutions align with your business objectives and integrate seamlessly with your operations.",
    },
    {
      title: "What are the costs associated with AI Consulting?",
      description: "The cost of artificial intelligence consulting services varies depending on the scope of your project. We offer flexible engagement models to fit your budget. We will discuss pricing options in detail during the initial consultation.",
    },
    {
      title: "How do you ensure the security and ethics of AI in my project?",
      description: "We take security and ethics very seriously. We follow industry best practices to ensure the responsible development and deployment of AI solutions. We can discuss our approach to security and ethics in more detail during the consultation.",
    }
]

const otherServices = [
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting/icon-chip.png`,
        title: "<span class=\"d-inline-block pt-sm-3\">AI Development</span>",
        linkTo: "/services/artificial-intelligence"
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting/icon-health.png`,
        title: "Business Intelligence",
        linkTo: "/services/business-intelligence"
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting/icon-robot-arm.png`,
        title: "<span class=\"d-inline-block pt-sm-3\">Data Engineering</span>",
        linkTo: "/services/data-engineering"
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting/icon-ioi.png`,
        title: "Data Science <span class=\"d-inline-block\">And Analytics</span> Solutions",
        linkTo: "/services/data-science-and-analytics"
    },
]

const ArtificialIntelligenceConsultingPage = () => {
    const { t } = useTranslation()
    return (
      <Layout>
        <SEO title={t("AI Consulting Services")} description={"Neurond offers customized AI consulting services to help your business successfully implement AI solutions into operations, paving the way to industry leadership."}/>
        <BannerFullScreen item={bannerContent} />

        <section className="content-background padding-y-100" style={{backgroundImage: `url(${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting/background-lines.jpg)`, backgroundPositionY:"top"}} >
            <div className="wrapper padding-y-100">
                <div className="row m-minus-15 align-items-center">
                    <div className="col-md-6 col-12 secondary-blue">
                        <h2 className="h2-text gradient-text --uppercase mb-5">Secret Weapon <span className="d-inline-block">for Business Growth</span></h2>
                        <p className="mb-4">Artificial intelligence is transforming every aspect of our lives, and businesses that leverage its potential are gaining a significant edge. But navigating the complexities of AI technology can be daunting.</p>
                        <p>Our AI experts will guide you through every step of the process, from identifying the right AI solutions. You gain access to specialized knowledge and experience, ensuring a smooth and successful AI integration that propels your business forward. </p>
                    </div>
                    <div className="offset-lg-1 col-lg-5 col-md-6 col-12">
                        {
                            businessGrowth.map((item, index) => {
                                if(businessGrowth.length == index + 1) {
                                    return (
                                        <div key={index}>
                                            <p className="h4-text primary-blue mb-2">{item.title}</p>
                                            <p className="sub-content secondary-blue">{item.content}</p>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div key={index}>
                                            <p className="h4-text primary-blue mb-2">{item.title}</p>
                                            <p className="sub-content  secondary-blue">{item.content}</p>
                                            <hr className="my-3"/>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            </div>
        </section>

        <section className="content-background padding-y-100" style={{backgroundImage: `url(${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting/background-transform-business.jpg)`}}>
            <div className="wrapper padding-y-50 pb-0">
                <div className="row m-minus-15">
                    <div className="offset-lg-3 col-lg-6 col-12 mb-5">
                        <h2 className="h2-text white --uppercase mb-5 text-center">Transforming Businesses, <span className="d-inline-block">One Solution at a Time</span></h2>
                    </div>
                    {
                        solutions.map((item, index) => {
                            return (
                                <div className="col-lg-4 col-md-6 col-12 px-md-4 text-center mt-5" key={index}>
                                    <img className="img-fluid mb-4 pb-3" alt={"Icon" + item.title} src={item.icon}/>
                                    <h3 className="h3-text mb-4 white --uppercase">{item.title}</h3>
                                    <p className="white" dangerouslySetInnerHTML={{ __html: item.content }}></p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>

        <section className="content-background --background-approach padding-y-100" style={{backgroundImage: `url(${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting/background-consulting-approaches.jpg)`,position:"relative"}}>
            <div className="ai-consulting__section-overlay"></div>
            <div className="wrapper pb-5">
                <div className="row m-minus-15">
                    <div className="offset-lg-1 col-lg-10 col-12 mb-5 pb-3">
                        <h2 className="h2-text white --uppercase mb-3 text-center">Our Comprehensive AI Consulting Approach</h2>
                        <p className="h3-text white text-center --font-weight-normal">Behind every effective AI strategy consulting solution we execute is <span className="d-inline-block">a tried-and-true process.</span></p>
                    </div>
                    <OrderedCard item={approachs}/>
                    <div className="col-12 text-center">
                        <ButtonContact />
                    </div>
                </div>
            </div>
        </section>

        <section className="padding-y-100 pb-5 gradient-radial-background">
            <div className="wrapper py-4">
                <div className="row m-minus-15">
                    <div className="col-lg-4 col-md-6 col-12 mb-4">
                        <p className="sub-text-h2">SERVICES</p>
                        <h2 className="h2-text gradient-text --uppercase mb-5">
                            Custom AI Solutions for Unique Challenges
                        </h2>
                    </div>
                    {
                        customSolutions.map((item, index) => {
                            return(
                                <div className="col-lg-4 col-md-6 col-12 mb-4">
                                    <WhiteBox item={item}/>
                                </div>
                            )
                        })
                    }
                    <div className="col-lg-3 col-md-6 col-12 mb-4 align-self-end pb-4 pl-md-4">
                        <p className="h3-text secondary-blue --font-weight-normal mb-4">We partner with businesses of all sizes across varied industries.</p>
                        <ButtonContact item={{customClass:"--alt", customText:"Let's Talk"}}/>
                    </div>
                </div>
            </div>
        </section>

        <section className="padding-y-100">
            <div className="wrapper padding-y-50 pb-0 content-background" style={{backgroundImage: `url(${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting/background-clients.png)`}}>
                <h2 className="h2-text --font-weight-normal text-center">
                    <span className="primary-blue">Powering the world’s best product teams.</span><br/>From next-gen startups to established enterprises.
                </h2>

                <div className="row m-minus-15 justify-content-center align-items-center text-center padding-y-100 pb-5">
                    {
                        clientList.map((item, index) => {
                            return (
                                <div className="col-md-3 col-sm-6 col-12 mb-5">
                                    <Link to={item.linkTo}><img className="img-fluid" alt={"Logo" + index} src={item.url}/></Link>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="row m-minus-15">
                    {
                        quoteBlocks.map((item, index) => {
                            return (
                                <div className="col-lg-4 col-md-6 col-12 mb-5" key={index}>
                                    <QuoteBlocks item={item} />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>

        <section className="wrapper">
            <div className="row m-minus-15 padding-y-100 pt-0">
                <div className="col-md-4 col-12">
                    <p className="sub-text-h2">STILL WONDERING ABOUT AI CONSULTING?</p>
                    <h2 className="h2-text gradient-text --uppercase mb-5">
                        We've Got Answers!
                    </h2>
                </div>
                <div className="offset-md-1 col-md-7 ai-consulting-faq">
                    <CustomAccordion data={faqs}/>
                </div>
            </div>
        </section>

        <section className="content-background padding-y-100 mt-5" style={{backgroundImage: `url(${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting/background-services.jpg)`}}>
            <div className="wrapper --flex-row align-items-start">
                <div className="col-lg-4 col-12 px-0">
                    <p className="sub-text-h2">SERVICES</p>
                    <h2 className="h2-text white --uppercase mb-5">
                        Other Services
                    </h2>
                </div>
                <div className="offset-lg-1 col-lg-7 px-0">
                    <div className="grid-2by2">
                    {
                        otherServices.map((item, index) => {
                            return(
                                <WhiteBox item={item}/>
                            )
                        })
                    }
                    </div>
                </div>
            </div>
        </section>
      </Layout>
    )
}

export default ArtificialIntelligenceConsultingPage
