import React from "react"
import { Link } from "gatsby"

const ButtonContact = prop => {
    if(prop.item) {
        return (
            <Link to={prop.item.linkTo} className={"link-button " + prop.item.customClass}>{prop.item.customText} <span>🡢</span></Link>
        )
    } else {
        return (
            <Link to="/contact" className="link-button">Let's Talk 🡢</Link>
        )
    }
}

export default ButtonContact
